import UTMQueryParser from "./src/helpers/utm-query-parser";

export const onRouteUpdate = ({ location, prevLocation }) => {
  // Segment page()
  window.analytics &&
    window.analytics.page("", {
      ...UTMQueryParser(),
      path: location.pathname,
      url: location.href,
      search: location.search,
      referrer: prevLocation?.href,
      title: "",
    });

  // Send a page_view event to the GTM data layer
  // wrap inside a timeout to ensure the title has properly been changed
  setTimeout(() => {
    window.dataLayer && window.dataLayer.push({ event: "custom_page_view" });
  }, 50);
};

export const shouldUpdateScroll = ({ routerProps, prevRouterProps }) => {
  // If the user is navigating around (there is a previous page)
  if (prevRouterProps?.location?.pathname) {
    // Preserve scroll when the page location is the same (ignore query params)
    if (
      routerProps?.location.pathname === prevRouterProps?.location?.pathname
    ) {
      return false;
    }

    // Preserve scroll if the only difference is the /limit/<number> on blog pages
    if (routerProps?.location?.pathname?.includes("/limit")) {
      const next = routerProps?.location?.pathname.split("/limit/")?.[0];
      const prev =
        prevRouterProps?.location?.pathname.split("/limit/")?.[0] ??
        prevRouterProps?.location?.pathname;

      if (next === prev) {
        return false;
      }
    }
  }

  // Default Gatsby behavior
  else return true;
};
