exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-en-404-js": () => import("./../../../src/pages/en/404.js" /* webpackChunkName: "component---src-pages-en-404-js" */),
  "component---src-pages-fr-404-js": () => import("./../../../src/pages/fr/404.js" /* webpackChunkName: "component---src-pages-fr-404-js" */),
  "component---src-pages-nl-404-js": () => import("./../../../src/pages/nl/404.js" /* webpackChunkName: "component---src-pages-nl-404-js" */),
  "component---src-pages-se-404-js": () => import("./../../../src/pages/se/404.js" /* webpackChunkName: "component---src-pages-se-404-js" */),
  "component---src-pages-uk-404-js": () => import("./../../../src/pages/uk/404.js" /* webpackChunkName: "component---src-pages-uk-404-js" */),
  "component---src-templates-blog-author-page-js": () => import("./../../../src/templates/BlogAuthorPage.js" /* webpackChunkName: "component---src-templates-blog-author-page-js" */),
  "component---src-templates-blog-category-page-js": () => import("./../../../src/templates/BlogCategoryPage.js" /* webpackChunkName: "component---src-templates-blog-category-page-js" */),
  "component---src-templates-blog-index-page-js": () => import("./../../../src/templates/BlogIndexPage.js" /* webpackChunkName: "component---src-templates-blog-index-page-js" */),
  "component---src-templates-blog-post-page-js": () => import("./../../../src/templates/BlogPostPage.js" /* webpackChunkName: "component---src-templates-blog-post-page-js" */),
  "component---src-templates-blog-tag-page-js": () => import("./../../../src/templates/BlogTagPage.js" /* webpackChunkName: "component---src-templates-blog-tag-page-js" */),
  "component---src-templates-product-pages-js": () => import("./../../../src/templates/ProductPages.js" /* webpackChunkName: "component---src-templates-product-pages-js" */)
}

