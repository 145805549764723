import queryString from "query-string";

const UTMQueryParser = () => {
  if (typeof window === "undefined") return;
  let parsed = queryString.parse(window.location.search);

  const utmObj = {};
  if ("utm_term" in parsed)
    Object.assign(utmObj, { utm_term: parsed.utm_term });
  if ("utm_source" in parsed)
    Object.assign(utmObj, { utm_source: parsed.utm_source });
  if ("utm_content" in parsed)
    Object.assign(utmObj, { utm_content: parsed.utm_content });
  if ("utm_campaign" in parsed)
    Object.assign(utmObj, { utm_campaign: parsed.utm_campaign });
  if ("utm_medium" in parsed)
    Object.assign(utmObj, { utm_medium: parsed.utm_medium });

  return utmObj;
};

export default UTMQueryParser;
